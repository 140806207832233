.App {
  text-align: center;
  background-color: #000;
  /* Background color black */
  color: #9D7C36;
  /* Text color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Nanum Gothic', sans-serif;
  /* Updated to Nanum Gothic */
}

.App-content {
  padding: 20px;
}

.smaller-font {
  font-size: 0.8em;
  /* Adjusted for better readability */
  padding: 20px;
}

.LEH-logo {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
  /* Reduced margin for mobile */
}

.Health-info h1,
.Health-info p {
  margin: 0;
  font-size: 2em;
  /* Adjust based on preference */
  font-weight: 400;
  /* Regular font weight */
}

/* Specific adjustments for smaller screens */
@media (max-width: 768px) {
  .LEH-logo {
    max-width: 75%;
    /* Larger logo on smaller screens */
  }

  .Health-info h1,
  .Health-info p {
    font-size: 1.5em;
    /* Smaller text on smaller screens */
  }

  .smaller-font {
    font-size: 0.7em;
    /* Even smaller font for the specific class */
  }
}

/* If you want a specific element to be bolder */
.Health-info p {
  font-weight: 700;
  /* Bold font weight */
}